import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Section from "../section"

export default ({}) => {
  const data = useStaticQuery(graphql`
    query {
      strapiSection(strapi_id: { eq: 9 }) {
        id
        title
        strapi_id
        articles {
          title
          id
          strapi_id
        }
      }
    }
  `)

  return <Section title="实用工具" section={data.strapiSection} />
}

import React, { useEffect } from "react"
import OpenApp from "open-app"

import Layout from "../components/layout"
import Banner from "../components/banner"
import Digest from "../components/tokenlon/digest"
import Guide from "../components/tokenlon/guide"
import Feature from "../components/tokenlon/feature"
import { updateFavicon } from "../utils"

import "../assets/css/main.css"

import BANNER_IMG from "../images/tokenlon/banner.svg"

const IndexPage = ({}) => {
  useEffect(() => {
    updateFavicon()
    new OpenApp({})
    if ((window as any).imToken) {
      ;(window as any).imToken.callAPI("navigator.configure", {
        navigatorColor: "#7B7EE8",
      })
    }
  }, [])
  return (
    <Layout title="Tokenlon 指南">
      <Banner
        src={BANNER_IMG}
        title="Tokenlon"
        subtitle="指南"
        isTokenlon={true}
      />
      <Digest />
      <Guide />
      <Feature />
      <div style={{ height: 45 }} />
    </Layout>
  )
}
export const Head = () => <title>Tokenlon 指南</title>
export default IndexPage

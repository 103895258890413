import React from "react"

export default ({ src, title, subtitle, isTokenlon }) => {
  return (
    <div className={`banner ${isTokenlon ? 'tokenlon-banner' : ''}`}>
      <div className="background">

      </div>
      <div className="frontground">
        <div className="titles">
          <p className="title">{title}</p>
          <p className="subtitle">{subtitle}</p>
        </div>
        <img src={src} alt="banner" />
      </div>
    </div>
  )
}
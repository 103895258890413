export const updateFavicon = () => {
  const pathname = window.location.pathname
  let isTokenlon = false
  const _isTokenlon = window.localStorage.getItem('isTokenlon')
  isTokenlon = _isTokenlon === 'true'
  if (pathname.includes('/tokenlon')) {
    isTokenlon = true
    window.localStorage.setItem('isTokenlon', 'true')
  } else if (pathname.includes('/imtoken')) {
    isTokenlon = false
    window.localStorage.setItem('isTokenlon', 'false')
  }

  const _href = isTokenlon ? 'https://aws-v2-cdn.token.im/app-mainnet-production/tokens/icons/tokenlon.png' : 'https://aws-v2-cdn.token.im/app-mainnet-production/tokens/icons/imToken.png'
  const nodeList = document.getElementsByTagName("link");
  for (var i = 0; i < nodeList.length; i++)
  {
    const rel = nodeList[i].getAttribute('rel')
      if(['icon', 'shortcut icon', 'apple-touch-icon'].includes(rel)){
          nodeList[i].setAttribute('href', _href);
      }
  } 
}

export const isiPhoneX = () => {
  if (typeof window !== 'undefined' && window) {
    return (
      /iphone/gi.test(window.navigator.userAgent) && window.screen.height >= 812
    )
  }
  return false
}
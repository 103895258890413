import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import GUIDE1 from "../../images/tokenlon/Tokenlon-1.svg"
import { Link } from "gatsby"

export default ({}) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiCategory {
        edges {
          node {
            id
            title
            strapi_id
          }
        }
      }
    }
  `)

  const edges = data.allStrapiCategory.edges

  const catSettings = [
    {
      color: "#F67676",
      img: GUIDE1,
      item: edges.find(t => t.node.strapi_id === 11),
    },
  ]

  return (
    <div className="section guide" id="guide">
      <p className="section-title">快速入门</p>
      <div className="guide-wrap">
        {catSettings.map((t, idx) => {
          const node = t.item.node
          return (
            <Link
              key={node.id}
              to={`/category/${node.strapi_id}`}
              className="guide-card"
            >
              <p
                className="guide-title"
                style={{ color: catSettings[idx].color }}
              >
                {node.title}
              </p>
              <img src={catSettings[idx].img} alt={node.title} />
            </Link>
          )
        })}
      </div>
    </div>
  )
}
